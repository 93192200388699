export function useCurrencyFormat(currency?: string, amount: number, country_code?: string, symbolOnly: boolean, numberOnly: boolean, shortNumber: boolean) {
  const datas = ref({ currency: 'USD', country_code: 'en-US', total: amount || 0 })
  const usedCurrency = [
    { currency: 'USD', country_code: 'en-US' },
    { currency: 'MYR', country_code: 'ms-MY' },
    { currency: 'IDR', country_code: 'id' },
    { currency: 'INR', country_code: 'hi-IN' },
    { currency: 'MXN', country_code: 'es-MX' },
    { currency: 'TRY', country_code: 'tr-TR' },
    { currency: 'PHP', country_code: 'ph' },
    { currency: 'SGD', country_code: 'zh-SG' },
    { currency: 'THB', country_code: 'th' },
    { currency: 'VND', country_code: 'vi' },
    { currency: 'GBP', country_code: 'en-GB' },
    { currency: 'TWD', country_code: 'zh-TW' },
    { currency: 'HKD', country_code: 'zh-HK' },
  ]
  if (!isEmpty(currency)) {
    const res = usedCurrency.find(item => item.currency === currency)
    datas.value = { currency: res?.currency, country_code: res?.country_code, total: amount || 0 }
  }
  else if (!isEmpty(country_code)) {
    const res = usedCurrency.find(item => item.country_code === country_code)
    datas.value = { currency: res?.currency, country_code: res?.country_code, total: amount || 0 }
  }

  if (symbolOnly) {
    return new Intl.NumberFormat(datas.value.country_code, { style: 'currency', currency: datas.value.currency, minimumFractionDigits: 0, currencyDisplay: 'narrowSymbol' }).formatToParts(datas.value.total).find(part => part.type === 'currency').value
  }
  else if (numberOnly) {
    if (shortNumber)
      return shortenNumber(datas.value.total)
    else
      return datas.value.total.toLocaleString(datas.value.country_code, { minimumFractionDigits: 0 })
  }
  else {
    const symbol = new Intl.NumberFormat(datas.value.country_code, { style: 'currency', currency: datas.value.currency, minimumFractionDigits: 0, currencyDisplay: 'symbol' }).formatToParts(datas.value.total).find(part => part.type === 'currency').value

    if (shortNumber)
      return `${symbol} ${shortenNumber(datas.value.total)}`
    else
      return `${symbol} ${datas.value.total.toLocaleString(datas.value.country_code, { minimumFractionDigits: 0 })}`
  }
}

function shortenNumber(amount: number) {
  if (Math.round(amount) >= 1e12)
    return `${(Math.round(amount) / 1e12).toFixed(0)}t`
  else if (Math.round(amount) >= 1e9)
    return `${(Math.round(amount) / 1e9).toFixed(0)}b`
  else if (Math.round(amount) >= 1e6)
    return `${(Math.round(amount) / 1e6).toFixed(0)}m`
  else if (Math.round(amount) >= 1e3)
    return `${(Math.round(amount) / 1e3).toFixed(0)}k`
  else
    return `${Math.round(amount).toString()}`
}
